<script setup>
import { onMounted, ref, watch, onUnmounted } from 'vue'
import { useStore } from 'vuex'
import router from '@/router'
import { returnLanguage } from '../../assets/js/language'
import AboutBanner from './components/about-banner'
import NewList from './components/newlist'
import { getList } from '@/api/new'

const store = useStore()
// 拿到vuex中的分类列表
const langtype = store.state.home.type
// eslint-disable-next-line no-undef
const langvalue = ref(returnLanguage(langtype))
// console.log(langvalue.value.top.one, '测试')

const checkedtype = ref(langvalue.value.news.three)
const type = ref(langtype)
let kList = ref([])
let page = ref(1)
let limit = ref(10)

const getNewList = () => {
  getList(page.value, limit.value, 30).then(res => {
    let {
      data: { count, list }
    } = res
    kList.value = list
  })
}
const toInfo = id => {
  router.push({
    path: `/kinfo`,
    query: { id: id }
  })
}
watch(
  () => store.state.home.type,
  newVal => {
    langvalue.value = returnLanguage(newVal)
    checkedtype.value = returnLanguage(newVal).news.three
    type.value = newVal
    getNewList()
  },
  { immediate: true }
)
</script>
<template>
  <div class="connew">
    <AboutBanner :checkedtype="checkedtype" title="POPULAR SCIENCE KNOWLEDGE" />
    <div class="new_main">
      <div class="title">
        <span class="line"></span>
        <span class="text">{{ langvalue.title.one }}</span>
        <span class="line"></span>
      </div>
      <div class="content">
        <div
          class="item"
          v-for="(item, index) in kList"
          :key="index"
          @click="toInfo(item.id)"
        >
          {{ index + 1 }}.{{ item.title }}
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="less" scoped>
.connew {
  background: #f8f8f8;
  .new_main {
    width: 1240px;
    padding: 96px 0 200px;
    margin: 0 auto;

    .title {
      font-size: 28px;
      font-weight: bold;
      color: #100e34;
      font-family: 'PingFang SC';
      font-size: 40px;
      font-weight: 400;
      display: flex;
      justify-content: center;
      align-items: center;
      letter-spacing: 2px;
      .line {
        display: inline-block;
        width: 100px;
        height: 2px;
        background: #d3d5e0;
      }
      .text {
        margin: 0 10px;
      }
    }

    .content {
      padding: 80px 0 0;

      .item {
        border-radius: 20px;
        padding: 20px 88px;
        background: #fff;
        font-family: 'PingFang SC';
        font-size: 28px;
        font-weight: 600;
        line-height: 40px;
        margin-bottom: 20px;
      }
    }
  }
}
</style>
